export const STORE_DELIVERY_ADDRESS = {
  type: 'store',
  _id: 'STORE_ID',
  title: 'Bio Culture',
  address: '28 rue de Wolfenbüttel',
  zipCode: '92310',
  city: 'Sèvres',
  complement: '',
  comment: '',
};
