import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
});

export default function UpdateOrderAdressForm({ isEditable = true, selectedDeliveryAddress }) {
  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
        disabled={!isEditable}
        label="Adresse de livraison"
        variant="outlined"
        value={selectedDeliveryAddress.address}
      />
      <TextField
        disabled={!isEditable}
        label="Code postal"
        variant="outlined"
        value={selectedDeliveryAddress.zipCode}
      />
      <TextField disabled={!isEditable} label="Ville" variant="outlined" value={selectedDeliveryAddress.city} />
      <TextField
        label="Complément d'adresse"
        variant="outlined"
        multiline
        maxRows={4}
        value={selectedDeliveryAddress.complement}
      />
      <TextField label="Commentaire" variant="outlined" multiline maxRows={4} value={selectedDeliveryAddress.comment} />
    </form>
  );
}
