import { useEffect, useState } from 'react';
import { axiosGetRequest } from '../../../utils/axiosRequests';

export const useGetActiveProducts = () => {
  const [result, setResult] = useState({
    loading: true,
    products: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await axiosGetRequest(`/products/?isActive=true`);

      if (response.status === 200) {
        setResult({
          loading: false,
          products: response.data.data.products,
        });
      } else {
        setResult((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    };

    fetchData();
  }, []);

  return result;
};

export const useGetActiveBaskets = () => {
  const [result, setResult] = useState({
    loading: true,
    baskets: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await axiosGetRequest(`/baskets/?isActive=true`);

      if (response.status === 200) {
        setResult({
          loading: false,
          baskets: response.data.data.baskets,
        });
      } else {
        setResult((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    };

    fetchData();
  }, []);

  return result;
};
