import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Divider, Paper } from '@material-ui/core';
import { LocalShipping as LocalShippingIcon, Event as EventIcon, Schedule as ScheduleIcon } from '@material-ui/icons';

import HomeDeliveryController from './DeliveryTypeControllers/HomeDeliveryController';
import RelayDeliveryController from './DeliveryTypeControllers/RelayDeliveryController';
import StoreDeliveryController from './DeliveryTypeControllers/StoreDeliveryController';

import { deliveryTypeToLabel } from '../../../../shared/delivery/utils/delivery.utils';
import DeliveryTypeFormControl from '../../../../components/Admin/Delivery/DeliveryTypeFormControl';
import UpdateOrderAdressForm from '../../../../components/Order/Form/UpdateOrderAdressForm';
import {
  getStoreDeliveryAddress,
  getStoreDeliveryInfos,
} from '../../../../shared/delivery/utils/store/storeDelivery.utils';
import { STORE_DELIVERY_ADDRESS } from '../../../../shared/delivery/constants/store/store.constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    overflow: 'auto',

    '& > h3': {
      margin: '0 0 1rem 0',
    },

    '& > section:first-of-type': {
      display: 'grid',
      gridTemplateColumns: '1.5fr 3fr 3fr',
      gap: '1rem',
      marginBottom: '1rem',
    },
  },

  deliveryAdress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '1rem',
    marginBottom: '1rem',

    '& > .MuiDivider-root': {
      height: '250px',
    },

    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      width: '350px',
      gap: '1rem',

      '& > h4': {
        margin: '0',
      },
    },
  },

  header: {
    padding: theme.spacing(2),
    overflow: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr 0 1fr 0 1fr',
    gap: '1rem',
    marginBottom: '1rem',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      '& > p': {
        fontWeight: 'bold',

        '& > span': {
          display: 'block',
          fontWeight: 'normal',
          textTransform: 'capitalize',
        },
      },
    },
  },
}));

function HeaderSection({ selectedDeliveryType, selectedDeliveryDay }) {
  const classes = useStyles();

  const deliveryTypeLabel = deliveryTypeToLabel(selectedDeliveryType);

  return (
    <Paper className={classes.header} elevation={2}>
      <div>
        <LocalShippingIcon />
        <p>
          Type de livraison : <span>{deliveryTypeLabel}</span>
        </p>
      </div>
      <Divider orientation="vertical" light />
      <div>
        <EventIcon />
        <p>
          Date de livraison : <span>{selectedDeliveryDay.formattedDate}</span>
        </p>
      </div>
      <Divider orientation="vertical" light />
      <div>
        <ScheduleIcon />
        <p>
          Horaire de livraison : <span>{selectedDeliveryDay.schedule}</span>
        </p>
      </div>
    </Paper>
  );
}

export default function DeliveryInfoSummary({
  addresses,
  selectedDeliveryType,
  setSelectedDeliveryType,
  selectedDeliveryAddress,
  setSelectedDeliveryAddress,
  selectedDeliveryDay,
  setSelectedDeliveryDay,
}) {
  const classes = useStyles();

  const handleChangeDeliveryType = (event) => {
    const { value } = event.target;
    const isStoreDelivery = value === 'bioculture';
    setSelectedDeliveryType(value);
    setSelectedDeliveryAddress(isStoreDelivery ? STORE_DELIVERY_ADDRESS : null);
    setSelectedDeliveryDay(null);
  };

  return (
    <Paper className={classes.root}>
      <h3>Informations de livraison</h3>
      <section>
        <DeliveryTypeFormControl
          selectedDeliveryType={selectedDeliveryType}
          handleChangeDeliveryType={handleChangeDeliveryType}
        />

        {selectedDeliveryType === 'home' && (
          <>
            {addresses && (
              <HomeDeliveryController
                addresses={addresses}
                selectedDeliveryAddress={selectedDeliveryAddress}
                setSelectedDeliveryAddress={setSelectedDeliveryAddress}
                selectedDeliveryDay={selectedDeliveryDay}
                setSelectedDeliveryDay={setSelectedDeliveryDay}
              />
            )}
            {!addresses && <p>Aucune adresse pour cet utilisateur</p>}
          </>
        )}
        {selectedDeliveryType === 'relayPoint' && (
          <RelayDeliveryController
            selectedDeliveryAddress={selectedDeliveryAddress}
            selectedDeliveryDay={selectedDeliveryDay}
            setSelectedDeliveryAddress={setSelectedDeliveryAddress}
            setSelectedDeliveryDay={setSelectedDeliveryDay}
          />
        )}
        {selectedDeliveryType === 'bioculture' && (
          <StoreDeliveryController
            selectedDeliveryDay={selectedDeliveryDay}
            setSelectedDeliveryDay={setSelectedDeliveryDay}
          />
        )}
      </section>
      {!!(selectedDeliveryType && selectedDeliveryAddress && selectedDeliveryDay) && (
        <>
          <Divider style={{ marginBottom: '1rem' }} light />
          <HeaderSection selectedDeliveryType={selectedDeliveryType} selectedDeliveryDay={selectedDeliveryDay} />

          <section className={classes.deliveryAdress}>
            <div>
              <h4>Adresse de livraison :</h4>
              <UpdateOrderAdressForm
                isEditable={selectedDeliveryType !== 'bioculture'}
                selectedDeliveryAddress={selectedDeliveryAddress}
              />
            </div>
            <Divider light orientation="vertical" />
            <div>
              <h4>Adresse de facturation :</h4>
              <UpdateOrderAdressForm selectedDeliveryAddress={selectedDeliveryAddress} />
            </div>
          </section>
        </>
      )}
    </Paper>
  );
}
