import React from 'react';

import { Box, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

import { AddCategoryProduct } from '../../../components/Admin/Product/AddCategoryProduct';

import { useGetActiveBaskets, useGetActiveProducts } from '../../../shared/products/hooks/products.hooks';

import getOrganizeProductsByCategory from '../../../shared/products/utils/getOrganizeProductsByCategory';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    overflow: 'auto',

    '& > h3': {
      margin: '0 0 1rem 0',
    },
  },

  categories: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap: '1rem',
  },
}));

export default function AddProduct({ handleAddProduct, mb }) {
  const classes = useStyles();

  const { loading: productLoading, products } = useGetActiveProducts();
  const { loading: basketLoading, baskets } = useGetActiveBaskets();

  const isLoading = productLoading || basketLoading;

  const organizeProducts = getOrganizeProductsByCategory(isLoading ? [] : [...products, ...baskets]);
  const sortedCategoriesArray = Object.values(organizeProducts).sort((a, b) => a.categoryPosition - b.categoryPosition);

  return (
    <Box mb={mb}>
      {isLoading && <Skeleton variant="rect" width="100%" height={400} />}
      {!isLoading && (
        <Paper className={classes.root}>
          <h3>Ajouter des produits</h3>
          <section className={classes.categories}>
            {sortedCategoriesArray.map((category) => (
              <AddCategoryProduct key={category.categoryId} category={category} handleAddProduct={handleAddProduct} />
            ))}
          </section>
        </Paper>
      )}
    </Box>
  );
}
