import React from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { deliveryTypeToLabel } from '../../../shared/delivery/utils/delivery.utils';
import { AVAILABLE_DELIVERY_TYPES } from '../../../shared/delivery/constants/delivery.constants';

export default function DeliveryTypeFormControl({ selectedDeliveryType, handleChangeDeliveryType }) {
  return (
    <FormControl variant="outlined">
      <InputLabel>Type de livraison</InputLabel>
      <Select label="Type de livraison" value={selectedDeliveryType} onChange={handleChangeDeliveryType}>
        {AVAILABLE_DELIVERY_TYPES.map((type) => (
          <MenuItem key={type} value={type}>
            {deliveryTypeToLabel(type)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
