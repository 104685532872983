import axios from 'axios';
import { useEffect, useState } from 'react';
import { axiosGetRequest } from '../../../utils/axiosRequests';

/**
 * Custom hook to fetch user data by user ID.
 *
 * @param {string} id - The ID of the user to fetch.
 * @returns {Object} - An object containing loading state and user data.
 */
export const useGetUserById = (id) => {
  const [result, setResult] = useState({
    loading: true,
    user: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await axiosGetRequest(`/users/${id}`);

      if (response.status === 200) {
        setResult({
          loading: false,
          user: response.data.data.user,
        });
      } else {
        setResult((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    };

    fetchData();
  }, [id]);

  return result;
};
