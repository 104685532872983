import moment from 'moment/moment';
import React, { useCallback, useEffect, useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { stringDateToDeliveryDate } from '../../../../../shared/delivery/utils/delivery.utils';

export default function StoreDeliveryController({ selectedDeliveryDay, setSelectedDeliveryDay }) {
  const [stringDate, setStringDate] = React.useState(
    selectedDeliveryDay?.date.format('YYYY-MM-DD') ?? moment().format('YYYY-MM-DD')
  );

  const handleChangeDeliveryDay = useCallback(
    (event) => {
      const newDate = moment(event.target.value, 'YYYY-MM-DD');
      setStringDate(newDate.format('YYYY-MM-DD'));
      setSelectedDeliveryDay(stringDateToDeliveryDate(newDate, '9H00 - 17H30'));
    },
    [setStringDate, setSelectedDeliveryDay]
  );

  useEffect(() => {
    if (!selectedDeliveryDay) setSelectedDeliveryDay(stringDateToDeliveryDate(stringDate, '9H00 - 17H30'));
  }, [selectedDeliveryDay, stringDate, setSelectedDeliveryDay]);

  return (
    <form noValidate>
      <TextField
        label="Date de livraison"
        type="date"
        variant="outlined"
        defaultValue={stringDate}
        onChange={handleChangeDeliveryDay}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form>
  );
}
