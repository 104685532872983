export default function getOrganizeProductsByCategory(products) {
  return products.reduce((acc, product) => {
    const { category, subCategory } = product;

    if (!acc[category.id]) {
      acc[category.id] = {
        categoryId: category.id,
        categoryName: category.name,
        categoryPosition: category.position,
        subCategories: {},
      };
    }

    if (!acc[category.id].subCategories[subCategory.id]) {
      acc[category.id].subCategories[subCategory.id] = {
        subCategoryId: subCategory.id,
        subCategoryName: subCategory.name,
        subCategoryPosition: subCategory.position,
        products: [],
      };
    }

    acc[category.id].subCategories[subCategory.id].products.push({
      id: product.id,
      name: product.name,
      categoryName: category.name,
      subCategoryName: subCategory.name,
      price: product.price,
      tvaRate: product.tvaRate,
      size: product.size,
    });

    return acc;
  }, {});
}
