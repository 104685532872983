import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

export default function OrderStatusFormControl({ selectedStatus, handleChange }) {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Statut de paiement</InputLabel>
      <Select
        value={selectedStatus}
        onChange={handleChange}
        label="Statut de paiement"
        style={{ textTransform: 'capitalize' }}
      >
        <MenuItem value="waiting">En attente</MenuItem>
        <MenuItem value="canceled">Annulé</MenuItem>
        <MenuItem value="paid">Payé</MenuItem>
        <MenuItem value="refuse">Refusé</MenuItem>
        <MenuItem value="refund">Remboursé</MenuItem>
      </Select>
    </FormControl>
  );
}
