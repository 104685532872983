import React from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

export default function DeliveryAdressFormControl({ deliveryAddressId, adresses, handleChangeAddress }) {
  const hasAdress = !!adresses.length;

  return (
    <FormControl variant="outlined">
      <InputLabel>Adresse</InputLabel>
      <Select value={deliveryAddressId} label="Adresse" onChange={(event) => handleChangeAddress(event.target.value)}>
        {hasAdress &&
          adresses.map((adress) => (
            <MenuItem key={adress._id} value={adress._id}>
              {adress.isMain && 'Principal - '} {adress.street} - {adress.city} {adress.zipCode}
            </MenuItem>
          ))}
        {!hasAdress && <MenuItem value="None">Aucune adresse</MenuItem>}
      </Select>
    </FormControl>
  );
}
