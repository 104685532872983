import moment from 'moment';

export function getDeliveryCitySchedule({ schedules, interval }) {
  const { from, to } = interval;

  const result = [];

  const daysRange = [];
  while (from.isSameOrBefore(to)) {
    daysRange.push({
      day: from.day(),
      date: from.clone(),
    });
    from.add(1, 'days');
  }

  schedules.forEach((schedule) => {
    const dayOfWeek = moment().isoWeekday(schedule.day).day();

    const matchingDays = daysRange.filter((rangeDay) => rangeDay.day === dayOfWeek);
    const formattedDays = matchingDays.map((rangeDay) => ({
      date: rangeDay.date,
      startMoment: moment(`${rangeDay.date} ${schedule.startTime}`, 'YYYY-MM-DD HHmm'),
      formattedDate: rangeDay.date.format('dddd Do MMMM YYYY'),
      schedule: `${schedule.startTime} - ${schedule.endTime}`,
    }));

    result.push(...formattedDays);
  });

  return result.sort((a, b) => {
    if (a.date.isSame(b.date)) return a.startMoment.isAfter(b.startMoment) ? 1 : -1;
    if (a.date.isAfter(b.date)) return 1;
    if (a.date.isBefore(b.date)) return -1;
  });
}

export function deliveryTypeToLabel(type) {
  switch (type) {
    case 'home':
      return 'À domicile';
    case 'relayPoint':
      return 'En point relais';
    case 'bioculture':
      return 'Chez Bio Culture';
    default:
      return 'Incorrect delivery type';
  }
}

export function stringDateToDeliveryDate(stringDate, schedule) {
  const momentDate = moment(stringDate);

  return {
    date: momentDate,
    formattedDate: momentDate.format('dddd Do MMMM YYYY'),
    schedule: schedule ?? '9H00 - 17H30',
  };
}
