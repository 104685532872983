import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

export default function OrderAdvStatusFormControle({ selectedAdvStatus, handleChange }) {
  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Statut ADV</InputLabel>
      <Select
        value={selectedAdvStatus}
        onChange={handleChange}
        label="Statut ADV"
        style={{ textTransform: 'capitalize' }}
      >
        <MenuItem value="waiting">En attente</MenuItem>
        <MenuItem value="canceled">Annulé</MenuItem>
        <MenuItem value="validate">Validé</MenuItem>
        <MenuItem value="processed">Traité</MenuItem>
        <MenuItem value="archived">Archivé</MenuItem>
      </Select>
    </FormControl>
  );
}
