export function addressToDeliveryAddress(address) {
  return {
    type: 'home',
    _id: address._id,
    address: address.street,
    zipCode: address.zipCode,
    city: address.city,
    complement: address.complement,
    comment: address.comment,
  };
}
