import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';

export default function DeliveryRelaysFormControl({ relays, onChange }) {
  return (
    <Autocomplete
      onChange={(e, newElem) => onChange(newElem)}
      options={relays.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
      autoHighlight
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => `${option.name} (${option.city.name} - ${option.city.zipCode})`}
      renderInput={(params) => <TextField {...params} label="Point de livraison" variant="outlined" />}
    />
  );
}
