export function relayToDeliveryAddress(relay) {
  return {
    type: 'relay',
    _id: relay._id,
    address: relay.address.address,
    zipCode: relay.city.zipCode,
    city: relay.city.name,
    complement: relay.address.complement,
    comment: relay.address.comment,
  };
}
