import { useEffect, useState } from 'react';
import { axiosGetRequest } from '../../../utils/axiosRequests';
import Cookies from 'js-cookie';

export const useGetDeliveryCityByZip = ({ zipCode }) => {
  const token = Cookies.get('jwt');

  const [result, setResult] = useState({
    loading: true,
    deliveryCity: null,
  });

  if (!zipCode) return { loading: false, deliveryCity: null };
  useEffect(() => {
    const fetchData = async () => {
      const response = await axiosGetRequest(`/deliveryCities/delivery/${zipCode}`, token);

      if (response.status === 200) {
        setResult({
          loading: false,
          deliveryCity: response.data.data.deliveryCity,
        });
      } else {
        setResult((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    };

    fetchData();
  }, [zipCode]);

  return result;
};

export const useGetAllRelays = () => {
  const [result, setResult] = useState({
    loading: true,
    relays: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await axiosGetRequest(`/relayPoints/?isActive=true`);

      if (response.status === 200) {
        setResult({
          loading: false,
          relays: response.data.data.relayPoints,
        });
      } else {
        setResult((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    };

    fetchData();
  }, []);

  return result;
};
