import React, { useMemo } from 'react';

import moment from 'moment';

import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { stringDateToDeliveryDate } from '../../../shared/delivery/utils/delivery.utils';

const useStyles = makeStyles((theme) => ({
  forceDate: {
    display: 'grid',
    gridTemplateColumns: 'min(170px) min(170px)',
    gap: '1rem',
  },
}));

export default function DeliveryDateFormControl({ deliveryDays, selectedDeliveryDay, setSelectedDeliveryDay }) {
  const classes = useStyles();

  const [isForceDate, setIsForceDate] = React.useState(false);
  const handleForceDateChange = () => {
    if (isForceDate) {
      const matchingDeliveryDay = deliveryDays.find(
        (deliveryDay) =>
          deliveryDay.formattedDate === selectedDeliveryDay.formattedDate &&
          deliveryDay.schedule === selectedDeliveryDay.schedule
      );
      matchingDeliveryDay ? setSelectedDeliveryDay(matchingDeliveryDay) : setSelectedDeliveryDay(deliveryDays[0]);
    }
    setIsForceDate((prev) => !prev);
  };

  const dateMemo = useMemo(() => (selectedDeliveryDay?.date ?? moment()).format('YYYY-MM-DD'), [selectedDeliveryDay]);

  const scheduleMemo = useMemo(() => selectedDeliveryDay?.schedule ?? '9H00 - 17H30', [selectedDeliveryDay]);
  const deliveryDayIndex = useMemo(() => {
    if (!selectedDeliveryDay) return 0;
    return deliveryDays.findIndex(
      (day) => day.formattedDate === selectedDeliveryDay.formattedDate && day.schedule === selectedDeliveryDay.schedule
    );
  }, [deliveryDays, selectedDeliveryDay]);

  const handleChange = (event) => {
    const selectedDelireryDay = deliveryDays[event.target.value];
    setSelectedDeliveryDay(selectedDelireryDay);
  };

  const handleChangeForceDate = (event) => {
    return setSelectedDeliveryDay(stringDateToDeliveryDate(event.target.value, scheduleMemo));
  };
  const handleChangeForceSchedule = (event) => {
    return setSelectedDeliveryDay(stringDateToDeliveryDate(dateMemo, event.target.value));
  };

  return (
    <section>
      {!isForceDate && (
        <FormControl variant="outlined">
          <InputLabel>Livraison le</InputLabel>
          <Select
            value={deliveryDayIndex}
            label="Livraison le"
            onChange={handleChange}
            style={{ textTransform: 'capitalize' }}
          >
            {deliveryDays.length &&
              deliveryDays.map((deliveryDay, index) => (
                <MenuItem
                  key={`${deliveryDay.formattedDate}-${deliveryDay.schedule}`}
                  value={index}
                  style={{ textTransform: 'capitalize' }}
                >
                  {deliveryDay.formattedDate} - {deliveryDay.schedule}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
      {isForceDate && (
        <div className={classes.forceDate}>
          <form noValidate>
            <TextField
              label="Date de livraison"
              type="date"
              variant="outlined"
              defaultValue={dateMemo}
              onChange={handleChangeForceDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
          <TextField
            label="Horaire de livraison"
            type="text"
            variant="outlined"
            defaultValue={scheduleMemo}
            onChange={handleChangeForceSchedule}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      )}
      <FormControlLabel
        control={<Checkbox checked={isForceDate} name="forceDateCheckbox" onChange={handleForceDateChange} />}
        label="Forcer la date de livraison"
      />
    </section>
  );
}
