import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControl, InputLabel, Paper, Select } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    overflow: 'auto',

    '& > h4': {
      margin: '0 0 1rem 0',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    '& > .MuiFormControl-root': {
      gap: '0.5rem',
    },
  },
}));

export const AddCategoryProduct = ({ category, handleAddProduct }) => {
  const classes = useStyles();

  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const hasSelectedProduct = !!selectedProduct;

  const { categoryName, subCategories } = category;

  const sortedSubCategoriesArray = Object.values(subCategories).sort(
    (a, b) => a.subCategoryPosition - b.subCategoryPosition
  );

  const handleSelectedProduct = (event) => {
    const productId = event.target.value;
    const product = sortedSubCategoriesArray
      .flatMap((subCategory) => subCategory.products)
      .find((product) => product.id === productId);

    setSelectedProduct(product ?? null);
  };

  const handleClick = () => {
    if (hasSelectedProduct) handleAddProduct(selectedProduct);
  };

  return (
    <Paper className={classes.root}>
      <h4>{categoryName}</h4>
      <FormControl variant="outlined">
        <InputLabel>Produits</InputLabel>
        <Select native label="Produits" onChange={handleSelectedProduct}>
          <optgroup label="">
            <option aria-label="None" value="" />
          </optgroup>
          {sortedSubCategoriesArray.map((subCategory) => (
            <optgroup key={subCategory.subCategoryId} label={subCategory.subCategoryName}>
              {subCategory.products.map((product) => (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              ))}
            </optgroup>
          ))}
        </Select>
        <Button disabled={!hasSelectedProduct} variant="contained" color="primary" type="submit" onClick={handleClick}>
          Ajouter
        </Button>
      </FormControl>
    </Paper>
  );
};
